import { media } from '@glitz/core';
import React from 'react';
import { styled, theme } from '../Theme';

type PropType = {
  heading: string;
  bodyText: string;
};

function TermsModalContent({ heading, bodyText }: PropType) {
  return (
    <Wrapper>
      <Heading>{heading}</Heading>
      <styled.Div
        dangerouslySetInnerHTML={{
          __html: bodyText,
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div({
  color: theme.black,
  ...media(theme.mediaQuery.mediaMinMedium, {
    padding: {
      xy: theme.spacing(8),
    },
  }),
});

const Heading = styled.h1({
  marginBottom: theme.spacing(4),
});

export default TermsModalContent;
