import { EmptyCart } from './../Cart/Cart';

export async function SubmitOrder(
  checkoutPage: string,
  body: any,
  onSuccess: (x: any) => void,
  onFailure: (x: string) => void,
  id: string
) {
  const res = await fetch(`${checkoutPage}submit`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
  const data = await res.json();

  if (data.success) {
    EmptyCart(id);
    onSuccess(data.purchaseOrderId);
  } else {
    onFailure(data);
  }

  return data;
}
