import React, { useState, useEffect } from 'react';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import CheckoutPageModel from './Models/CheckoutPageModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { GetCart, EmptyCart } from '../Cart/Cart';
import ProductList from '../ProductList/ProductList';
import Grid from '../Shared/Grid/Grid';
import { GridSize } from '../Enums/GridSize.enum';
import KexLink from '../Shared/KexLink/KexLink';
import Modal from '../Shared/Modal/Modal';
import ClearCartModalContent from './ClearCartModalContent';
import useMedia from '../Shared/Hooks/useMedia';
import { CartIcon } from '../Shared/Icons';
import ValueDropDown from './../Shared/ValueDropDown/ValueDropDown';
import OrderSummary from './OrderSummary';
import { Button, FeedbackButton } from '../Shared/Button/Button';
import KexInput from './../Shared/Input/KexInput';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import KexCheckbox from '../Shared/KexCheckbox/KexCheckbox';
import Translate from '../Shared/Common/Translate';
import TermsModalContent from './TermsModalContent';
import { SubmitOrder } from './Checkout';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import AddressModel from './Models/AddressModel.interface';
import useEncodedCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';
import { InputType } from '../Enums/InputType.enum';
import { GiftSection } from './GiftsSection';
import { cartDataLayer, removeFromCartDataLayer } from '../utils/dataLayer.lib';
import './CheckoutPage.css';
const NOTE_MAX_LENGTH = 300;
const NOTE_MIN_LENGTH = 0;

function CheckoutPage() {
  const {
    shippingHeading,
    shippingAddresses,
    termsAndConditionsHeading,
    termsAndConditionsText,
    company,
    customerId,
    emptyCartRedirectUrl,
    manualShippingCostInformation,
    manualShippingCost,
    buyMoreLabel,
    notificationText,
  } = useCurrentPage<CheckoutPageModel>();
  const id = useEncodedCurrentUser();
  const cart = GetCart(id).cart;
  const isMobile = useMedia(theme.mediaQuery.mediaMaxMedium);
  const isTablet = useMedia(theme.mediaQuery.mediaMaxSmall);
  const [edit, setEdit] = useState<boolean>(false);
  const [showModal, toggleModal] = useState(false);
  const [activeShippingAddressKey, setShippingAddressKey] = useState(0);
  const [orderNote, setOrderNote] = useState<string>('');
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [showTermsModal, toggleTermsModal] = useState<boolean>(false);
  const [isDataLayerCalled, setIsDataLayerCalled] = useState<boolean>(false);

  const kexNavigate = useKexNavigate();

  const {
    translations: {
      'cartPage/cartHeader': cartHeader,
      'checkoutPage/orderHeader': orderHeader,
      'checkoutPage/companyHeader': companyHeader,
      'checkoutPage/customerNumber': customerNumberHeader,
      'checkoutPage/terms': termsLabel,
      'checkoutPage/termsActionText': termsLabelAction,
      'checkoutPage/orderNoteInput': orderNoteInputLabel,
      'checkoutPage/submit': submitLabel,
      'checkoutPage/clearCart': clearCartText,
      'checkoutPage/editCart': editCartText,
      'checkoutPage/orderNote': orderNoteText,
      'checkoutPage/noAddressFields': noAddressFieldText,
    },
    staticPages: { orderConfirmationPage, checkoutPage, productsPage },
    siteRoute,
  } = useAppSettingsData();

  const toggleModalState = () => {
    toggleModal(!showModal);
  };

  const toggleTermsModalState = () => {
    toggleTermsModal(!showTermsModal);
  };

  const toggleEdit = () => {
    setEdit(!edit);
  };

  useEffect(() => {
    if (emptyCartRedirectUrl) {
      if (emptyCartRedirectUrl === '') kexNavigate(`/${siteRoute}`);
      else kexNavigate(`${emptyCartRedirectUrl}`);
    }
  }, [emptyCartRedirectUrl, siteRoute, kexNavigate]);
  const clearCart = () => {
    toggleModalState();
  };

  const updateShippingAddress = (key: string) => {
    const shippingKey =
      shippingAddresses.findIndex(s => s.tepeAddressId === key) || 0;
    setShippingAddressKey(shippingKey);
  };

  const isCheckoutValid = () => {
    return termsAgreed && orderNote.length <= NOTE_MAX_LENGTH;
  };

  const onSubmitOrderSuccess = (purchaseOrderId: number) => {
    kexNavigate(`${orderConfirmationPage}?orderid=${purchaseOrderId}`);
  };

  const onSubmitOrderFailure = () => {};

  const submitOrder = async () => {
    if (isCheckoutValid()) {
      const body = {
        company,
        customerId,
        orderNote,
        tepeAddressId:
          shippingAddresses[activeShippingAddressKey]?.tepeAddressId,
      };

      return await SubmitOrder(
        checkoutPage,
        body,
        onSubmitOrderSuccess,
        onSubmitOrderFailure,
        id
      );
    }
  };

  const termsAction = () => {
    return (
      <TermsAction onClick={toggleTermsModalState}>
        {termsLabelAction}
      </TermsAction>
    );
  };

  const onCartClear = () => {
    removeFromCartDataLayer(
      cart?.lineItems,
      cart?.totalPriceForItems,
      cart?.currency
    );
    EmptyCart(id);
  };

  const goToProductPage = () => {
    kexNavigate(productsPage);
  };

  useEffect(() => {
    if (Object.keys(cart)?.length && !isDataLayerCalled) {
      cartDataLayer(cart);
      setIsDataLayerCalled(true);
    }
  }, [cart, isDataLayerCalled]);

  return (
    <>
      {showModal && (
        <Modal toggle={toggleModalState} overlay isUserAction>
          <ClearCartModalContent
            closeModal={toggleModalState}
            clearCart={onCartClear}
          />
        </Modal>
      )}
      {showTermsModal && (
        <Modal toggle={toggleTermsModalState} overlay isUserAction>
          <TermsModalContent
            heading={termsAndConditionsHeading}
            bodyText={termsAndConditionsText}
          />
        </Modal>
      )}
      <PageRoot>
        <Grid
          type={
            isMobile ? GridSize.Two : isTablet ? GridSize.Three : GridSize.Eight
          }
        >
          <Main>
            {notificationText && (
              <NotificationText
                className="notification-text"
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(notificationText),
                }}
              />
            )}
            <CartContainer>
              <CartContent>
                <TopRowContainer>
                  <Heading>{cartHeader}</Heading>
                  {isMobile ? (
                    <EditCart onClick={toggleEdit}>
                      <EditIcon />
                      {editCartText}
                    </EditCart>
                  ) : (
                    <ClearCart onClick={clearCart}>{clearCartText}</ClearCart>
                  )}
                </TopRowContainer>
                <ProductList
                  products={cart.lineItems}
                  isLoading={false}
                  allowEdit
                  edit={edit}
                  setEdit={setEdit}
                  currency={cart?.currency}
                />
                <GiftSection cart={cart} />
              </CartContent>
            </CartContainer>
            <BuyMoreButton isActive onClick={goToProductPage}>
              {buyMoreLabel}
            </BuyMoreButton>
            <Section>
              <Details>
                <OrderDetails>
                  <Heading>{orderHeader}</Heading>
                  <SubHeader>
                    {companyHeader} <Text>{company}</Text>
                  </SubHeader>
                  <SubHeader>
                    {customerNumberHeader} <Text>{customerId}</Text>
                  </SubHeader>
                  <Shipping>
                    <Label>{shippingHeading}</Label>
                    {shippingAddresses && (
                      <ValueDropDown
                        values={
                          shippingAddresses &&
                          shippingAddresses.map((s: AddressModel) => ({
                            heading: `${s.name} ${s.line1}`,
                            subHeading: `${s.line1} ${s.postalCode} ${s.city}`,
                            value: s.tepeAddressId,
                          }))
                        }
                        currentValue={
                          shippingAddresses.length > 0
                            ? {
                                heading: `${shippingAddresses[activeShippingAddressKey]?.name} ${shippingAddresses[activeShippingAddressKey]?.line1}`,
                                subHeading: `${shippingAddresses[activeShippingAddressKey]?.line1} ${shippingAddresses[activeShippingAddressKey]?.postalCode} ${shippingAddresses[activeShippingAddressKey]?.city}`,
                                value:
                                  shippingAddresses[activeShippingAddressKey]
                                    ?.tepeAddressId,
                              }
                            : {}
                        }
                        setSelectedValue={updateShippingAddress}
                        noValuesMsg={noAddressFieldText}
                      />
                    )}
                  </Shipping>
                  <OrderNote>
                    <Label>{orderNoteText}</Label>
                    <KexInputValidation>
                      <KexInput
                        inputType={InputType.TextArea}
                        title={orderNoteInputLabel}
                        css={StyledInputCss}
                        validation={{
                          maxLength: NOTE_MAX_LENGTH,
                          minLength: NOTE_MIN_LENGTH,
                          errorMessage: orderNoteInputLabel,
                        }}
                        onChange={setOrderNote}
                        value={orderNote}
                        useDebounce
                      />
                    </KexInputValidation>
                  </OrderNote>
                  <TermsWrapper>
                    <KexCheckbox
                      onChange={setTermsAgreed}
                      isChecked={termsAgreed}
                    />
                    <Translate
                      translation={termsLabel}
                      values={[termsAction()]}
                    />
                  </TermsWrapper>
                </OrderDetails>
                <OrderSummary
                  cart={cart}
                  numberOfItems={cart.numberOfItems}
                  totalPriceExclVat={cart.totalPriceExclVat}
                  totalFreightPrice={cart.totalFreightPrice}
                  promotions={cart.promotions}
                  totalPrice={cart.totalPrice}
                  totalPriceForItemsWithFreightPrice={
                    cart.totalPriceForItemsWithFreightPrice
                  }
                  manualShippingCost={manualShippingCost}
                  manualShippingCostInformation={manualShippingCostInformation}
                />
              </Details>
              <SubmitOrderButton
                action={submitOrder}
                disabled={!isCheckoutValid()}
              >
                {submitLabel}
              </SubmitOrderButton>
            </Section>
          </Main>
        </Grid>
      </PageRoot>
    </>
  );
}

const StyledInputCss: StyleOrStyleArray = {
  backgroundColor: theme.white,
};

const PageRoot = styled.div({
  width: '100%',
  height: '100%',
  paddingBottom: theme.spacing(16),
  ...media(theme.mediaQuery.mediaMinLarge, {
    paddingBottom: theme.none,
    backgroundColor: theme.lightGray,
    flexGrow: 1,
  }),
});

const CartContainer = styled.div({
  backgroundColor: theme.white,
  width: '100%',
  height: 'auto',
  justifyContent: 'center',
  margin: {
    top: theme.spacing(4),
    bottom: theme.spacing(2),
    x: 'auto',
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridGap: theme.spacing(8),
    margin: {
      top: theme.spacing(16),
      bottom: theme.spacing(8),
      x: 'auto',
    },
  }),
});

const EditCart = styled(KexLink, {
  display: 'flex',
  alignItems: 'center',
});

const EditIcon = styled(CartIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(2),
});

const CartContent = styled.div({
  fontWeight: 'lighter',
  color: theme.heading,
  backgroundColor: theme.white,

  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      xy: theme.spacing(8),
    },
  }),
  margin: { top: theme.none },
});
const NotificationText = styled.div({
  fontWeight: 'lighter',

  backgroundColor: theme.white,
  margin: {
    top: theme.spacing(4),
    bottom: theme.spacing(2),
    x: 'auto',
  },

  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      xy: theme.spacing(8),
    },
  }),
});

const TopRowContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ClearCart = styled(KexLink, {
  color: theme.blueDark,
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.bold,
});

const Heading = styled.h2({
  color: theme.black,
  font: { size: theme.eta, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(4),
});

const Main = styled.div({
  backgroundColor: theme.white,
  ...media(theme.mediaQuery.mediaMinLarge, {
    backgroundColor: theme.lightGray,
    gridColumnStart: '2',
    gridColumnEnd: '8',
  }),
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridColumnStart: '1',
    gridColumnEnd: '5',
  }),
});

const Section = styled.div({
  backgroundColor: theme.white,
  padding: {
    xy: theme.none,
  },
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(2),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      xy: theme.spacing(8),
    },
    marginTop: theme.spacing(8),
    marginBottom: theme.none,
  }),
});

const Details = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column-reverse',
  ...media(theme.mediaQuery.mediaMinLarge, {
    flexDirection: 'row',
  }),
});

const OrderDetails = styled.div({
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginRight: theme.spacing(4),
  }),
});

const SubHeader = styled.p({
  fontWeight: theme.fontWeight.bold,
  marginBottom: theme.spacing(2),
  color: theme.black,
});

const Text = styled.span({
  fontWeight: theme.fontWeight.normal,
  lineHeight: theme.lineHeight.loose,
});

const Shipping = styled.div({
  marginTop: theme.spacing(8),
  maxWidth: theme.shippingWrapperMaxWidth,
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginTop: theme.spacing(4),
  }),
});

const OrderNote = styled.div({
  margin: { y: theme.spacing(4) },
});

const Label = styled.p({
  fontWeight: theme.fontWeight.bold,
  marginBottom: theme.spacing(2),
  color: theme.black,
});

const TermsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const TermsAction = styled.span({
  textDecoration: 'underline',
  ':hover': {
    cursor: 'pointer',
  },
});

const SubmitOrderButton = styled(FeedbackButton, {
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.normal,
  margin: { x: 'auto', top: theme.spacing(8) },

  ...media(theme.mediaQuery.mediaMaxLarge, {
    width: '100%',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { x: theme.spacing(32) },
  }),
});

const BuyMoreButton = styled(Button, {
  marginLeft: 'auto',
  display: 'block',
  marginRight: 'auto',
});
export default CheckoutPage;
