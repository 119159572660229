import { media } from '@glitz/core';
import React from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import ContentDivider from '../Shared/Divider/Divider';
import { SmallButton } from '../Shared/Button/Button';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';

type PropType = {
  closeModal: () => void;
  clearCart: () => void;
};

function ClearCartModalContent({ closeModal, clearCart }: PropType) {
  const {
    translations: {
      'common/cancel': cancel,
      'checkoutPage/clearCartConformation': clearCartText,
      'checkoutPage/clearCart': clearCartHeader,
      'checkoutPage/clearCartInformation': clearcartInformation,
    },
    siteRoute,
  } = useAppSettingsData();

  const kexNavigate = useKexNavigate();

  const clearCartAndClose = () => {
    closeModal();
    clearCart();
    kexNavigate(`/${siteRoute}`);
    return <React.Fragment />;
  };

  return (
    <FormContainer>
      <Heading>{clearCartHeader}</Heading>
      <Text>{clearcartInformation}</Text>
      <MaxDivider />
      <ButtonContainer>
        <NoButton onClick={closeModal}>{cancel}</NoButton>
        <YesButton onClick={clearCartAndClose} isActive>
          {clearCartText}
        </YesButton>
      </ButtonContainer>
    </FormContainer>
  );
}

const FormContainer = styled.div({
  font: { size: theme.nu, weight: theme.fontWeight.bold },
  color: theme.black,
  backgroundColor: theme.white,
  width: '100%',
  lineHeight: theme.lineHeight.normal,
  ...media(theme.mediaQuery.mediaMinMedium, {
    font: { size: theme.mu, weight: theme.fontWeight.bold },
    padding: {
      xy: theme.spacing(8),
    },
  }),
});

const Heading = styled.div({
  fontSize: theme.epsilon,
  marginBottom: theme.spacing(2),
});

const Text = styled.p({
  fontSize: theme.gamma,
  fontWeight: theme.fontWeight.normal,
});

const MaxDivider = styled(ContentDivider, {
  width: '100%',
  backgroundColor: theme.black,
  margin: { y: theme.spacing(8) },
});

const ButtonContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

const YesButton = styled(SmallButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.blueDark,
  color: theme.white,
  ...media(theme.mediaQuery.mediaMinMedium, {
    width: 'auto',
  }),
});

const NoButton = styled(SmallButton, {
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.white,
  color: theme.blueDark,
  border: { xy: { color: theme.blueDark, width: theme.spacing(0.5) } },
  ...media(theme.mediaQuery.mediaMinMedium, {
    width: 'auto',
    float: 'right',
  }),
});

export default ClearCartModalContent;
