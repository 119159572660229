import React, { useState } from 'react';
import { StyleOrStyleArray } from '@glitz/type';
import KexCart from '../Cart/Models/KexCart.interface';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { AddCouponCode, RemoveCouponCode } from '../Cart/Cart';
import useEncodedCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';
import { CloseIcon } from '../Shared/Icons';
import { pseudo } from '@glitz/core';

interface ICouponCodeSectionProps {
  cart: KexCart;
}

export const CouponCodeSection = ({ cart }: ICouponCodeSectionProps) => {
  const id = useEncodedCurrentUser();
  const [couponCode, setCouponCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const StyledInputCss: StyleOrStyleArray = {
    backgroundColor: theme.white,
  };

  const {
    translations: {
      'coupon/couponAreaTitle': couponText,
      'coupon/addCouponCode': addCouponText,
    },
  } = useAppSettingsData();

  const addCoupon = async () => {
    const errorMessage = await AddCouponCode(couponCode, id);
    if (!errorMessage) {
      setCouponCode('');
    }
    setErrorMessage(errorMessage);
  };

  const removeCoupon = async (code: string) => {
    await RemoveCouponCode(code, id);
  };

  const onCodeChange = (code: string) => {
    if (errorMessage) {
      setErrorMessage(undefined);
    }
    setCouponCode(code);
  };

  return (
    <Container>
      <Label>{couponText ?? 'Lägg till rabattkod'}</Label>
      <InputArea>
        <InputRow>
          <CouponInput
            onChange={ev => onCodeChange(ev.target.value)}
            value={couponCode}
          />
          <AddCouponCodeButton onClick={addCoupon} disabled={couponCode === ''}>
            {addCouponText ?? 'Lägg till'}
          </AddCouponCodeButton>
        </InputRow>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </InputArea>
      <CouponsArea>
        {cart?.promotions
          ?.filter(x => x?.isCoupon)
          ?.map(code => {
            return (
              <CouponCodeRow>
                <div>{code.promotionName}</div>
                <div
                  onClick={() => removeCoupon(code.couponCode)}
                  style={{ width: '18px', height: '18px' }}
                >
                  <div style={{ fill: '#979797' }}>
                    <CloseIcon />
                  </div>
                </div>
              </CouponCodeRow>
            );
          })}
      </CouponsArea>
      <hr />
    </Container>
  );
};

const InputArea = styled.div({ display: 'flex', flexDirection: 'column' });
const InputRow = styled.div({
  display: 'flex',
  gap: '10px',
  alignItems: 'start',
});
const Container = styled.div({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '16px',
  minWidth: '340px',
});

const ErrorMessage = styled.span({
  color: '#F35625',
  fontSize: '14px',
  marginTop: '4px',
});

const CouponsArea = styled.div({
  display: 'inline-flex',
  flexDirection: 'column',
  gap: '8px',
});

const Label = styled.p({
  fontWeight: theme.fontWeight.bold,
  color: theme.black,
  fontSize: theme.gamma,
});

const CouponInput = styled.input({
  height: '32px',
  lineHeight: '32px',
  fontSize: '16px',
  border: { xy: { width: '1px' } },
  paddingLeft: '8px',
  flex: { grow: 1, shrink: 1, basis: '0' },
  outline: {
    width: 0,
  },
  borderRadius: '4px',
});
const AddCouponCodeButton = styled.button({
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  fontSize: '16px',
  alignItems: 'center',
  padding: { y: '0px', x: '16px' },
  backgroundColor: theme.blueDark,
  borderRadius: '4px',
  color: theme.white,
  ...pseudo(':hover', {
    backgroundColor: theme.primary,
    border: { xy: { color: theme.primary } },
  }),
});

const CouponCodeRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '32px',
  lineHeight: '32px',
});
