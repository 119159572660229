import React, { useEffect, useState } from 'react';
import KexCart from '../Cart/Models/KexCart.interface';
import IconSwitch from '../Shared/Icons/IconsSwitch';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import { pseudo } from '@glitz/core';
import PromotionModel from '../Promotions/PromotionModel.interface';

interface IGiftSectionProps {
  cart: KexCart;
}
export const GiftSection = ({ cart }: IGiftSectionProps) => {
  const [promotions, setPromotions] = useState<PromotionModel[]>([]);

  useEffect(() => {
    if (cart) {
      setPromotions(cart.promotions?.filter(p => p.gifts != null));
    }
  }, [cart]);

  return promotions?.length > 0 ? (
    <div>
      {promotions.map(promotion => {
        return (
          <>
            <GiftTitle>{promotion.promotionName}</GiftTitle>
            {promotion.gifts?.map(gift => {
              return (
                <Details>
                  <Title>{gift.name}</Title>
                  <DetailText>
                    <Icon>{IconSwitch(gift.packageKey)}</Icon>
                    {gift.package.description} - {gift.package.amountInPackage}{' '}
                    {gift.package.unit}
                  </DetailText>
                </Details>
              );
            })}
          </>
        );
      })}
    </div>
  ) : null;
};

const DetailText = styled.span({
  font: {
    size: theme.alpha,
  },
  color: theme.black,
  margin: { bottom: theme.spacing(1) },
  ...pseudo(':last-of-type', {
    margin: { bottom: theme.spacing(2) },
  }),
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
});

const Icon = styled.div({
  paddingRight: theme.spacing(2),
});
const Details = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const Title = styled.span({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  margin: { bottom: theme.spacing(2) },
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.black,
});

const GiftTitle = styled.h3({
  color: theme.black,
  marginBottom: '8px',
});
