import React from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { media } from '@glitz/core';
import useMedia from '../Shared/Hooks/useMedia';
import PromotionModel from './Models/PromotionModel.interface';
import Price from '../Shared/Price/Price';
import TepeNotification from '../Shared/Common/TepeNotification';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import { CouponCodeSection } from './CouponCodeSection';
import KexCart from '../Cart/Models/KexCart.interface';

type PropType = {
  cart: KexCart;
  numberOfItems: number;
  totalPriceExclVat: string;
  totalFreightPrice: string;
  promotions: PromotionModel[];
  totalPrice: string;
  totalPriceForItemsWithFreightPrice: string;
  manualShippingCostInformation?: string;
  manualShippingCost?: boolean;
};

function OrderSummary({
  cart,
  numberOfItems,
  totalPriceExclVat,
  totalFreightPrice,
  promotions,
  totalPrice,
  totalPriceForItemsWithFreightPrice,
  manualShippingCostInformation,
  manualShippingCost = false,
}: PropType) {
  const {
    translations: {
      'checkoutPage/amount': amountlabel,
      'checkoutPage/totalExVat': totalExVatLabel,
      'checkoutPage/total': totalLabel,
      'checkoutPage/ship': freightLabel,
      'checkoutPage/orderDetailHeader': orderDetailHeader,
      'checkoutPage/freeShipping': freeShippingText,
      'checkoutPage/manualShipping': manualShippingLabel,
    },
  } = useAppSettingsData();

  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);
  return (
    <Container>
      <CouponCodeSection cart={cart} />
      <Summary>
        {!isMobile && <OrderHeading>{orderDetailHeader}</OrderHeading>}
        {!!numberOfItems && (
          <OrderLine>
            {amountlabel} <Value>{numberOfItems}</Value>
          </OrderLine>
        )}
        {totalPriceExclVat && (
          <OrderLine>
            {totalExVatLabel} <Value>{totalPriceExclVat}</Value>
          </OrderLine>
        )}
        {promotions &&
          promotions.map((promotion, i) => (
            <OrderLine key={promotion.promotionName + i}>
              {promotion.promotionName}
              <Value>{promotion.promotionDiscountAmount}</Value>
            </OrderLine>
          ))}
        <OrderLine>
          {freightLabel}
          <Value>
            {manualShippingCost
              ? manualShippingLabel
              : !!totalFreightPrice
              ? totalFreightPrice
              : freeShippingText}
          </Value>
        </OrderLine>
        {totalPrice && (
          <OrderLine>
            {totalLabel}
            <Price
              price={totalPriceForItemsWithFreightPrice}
              loweredPrice={totalPrice}
              isSmall={true}
              isRow={false}
            />
          </OrderLine>
        )}
      </Summary>
      {manualShippingCost && manualShippingCostInformation && (
        <NotificationWrapper>
          <TepeNotification isError={false} greyBackground>
            <XhtmlComponent content={manualShippingCostInformation} />
          </TepeNotification>
        </NotificationWrapper>
      )}
    </Container>
  );
}

export default OrderSummary;

const Summary = styled.div({
  backgroundColor: theme.lightGray,
  padding: {
    xy: theme.spacing(4),
  },
});

const Container = styled.div({
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      xy: theme.spacing(8),
    },
    marginLeft: theme.spacing(4),
  }),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    marginBottom: theme.spacing(8),
    textAlign: 'right',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: theme.orderSummaryMaxWidth,
    alignSelf: 'flex-start',
  }),
});

const NotificationWrapper = styled.div({
  marginTop: theme.spacing(4),
});

const OrderHeading = styled.h3({
  font: {
    size: theme.epsilon,
    weight: theme.fontWeight.bold,
  },
  color: theme.black,
  marginBottom: theme.spacing(4),
});

const OrderLine = styled.div({
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.loose,
  marginBottom: theme.spacing(4),
  color: theme.black,
  fontSize: theme.beta,
  ...media(theme.mediaQuery.mediaMinLarge, {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  }),
});

const Value = styled.span({
  fontWeight: theme.fontWeight.normal,
  whiteSpace: 'nowrap',
  margin: {
    left: theme.spacing(2),
    y: 'auto',
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: {
      left: theme.spacing(4),
    },
  }),
});
